import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "ADDRESS",
    desc: (
      <span className="uppercase">
        Kirti Nagar C Sector, NarSingh Ji Ki Pyau, Opp UCO Bank, magra punjla
        colony, Jodhpur, Jodhpur, Rajasthan, 342007.
      </span>
    ),
  },
  {
    title: "EMAIL",
    desc: (
      <a
        href="mailto:info@shrilimbasfashion.com"
        className="no-underline text-inherit"
      >
        info@shrilimbasfashion.com
      </a>
    ),
  },
  {
    title: "PHONE",
    desc: (
      <a href="tel:+917891550001" className="no-underline text-inherit">
        +91 7891550001
      </a>
    ),
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(formData); // This will log the object containing form data
    // You can now perform any further actions with the form data, such as sending it to a server
  };
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Shop - eCommerce React Template</title>
      </Helmet>
      <div className="mb-20">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>Phone Number</Label>
                  <Input
                    type="tel"
                    placeholder="+91 7232003300"
                    className="mt-1"
                    maxLength={13}
                    minLength={10}
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                    className="mt-1"
                    rows={6}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-24 lg:py-28 w-full lg:h-[700px] sm:h-[500px] h-[600px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1788.028716471509!2d73.0653651!3d26.324639700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418dd30e2976a3%3A0xda1cbbe62b95c71!2sNar%20Singh%20Ji%20Ki%20Piyau%20Circle!5e0!3m2!1sen!2sin!4v1712660722711!5m2!1sen!2sin"
            style={{ border: 0, width: "100%", height: "100%" }}
            loading="lazy"
          ></iframe>
          {/* <SectionPromo1 /> */}
        </div>
      </div>
    </div>
  );
};

export default PageContact;
