import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.jpeg";
import logoLightImg from "images/logo-light.svg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  scrolled?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
  scrolled
}) => {
  // console.log("scrolled value--->",scrolled);
  return (
    <Link
      to="/"
      className={`ttnc-logo transition-all ease-in-out duration-300 inline-block text-slate-600 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-8 sm:max-h-20 ${
            imgLight ? "dark:hidden" : ""
          }`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-8 sm:max-h-10 dark:block"
          src={img}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
