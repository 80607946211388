import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import type { CollapseProps } from "antd";
import { Collapse, ConfigProvider } from "antd";

const DEMO_DATA = [
  {
    name: "Description",
    content:
      "Fashion is a form of self-expression and autonomy at a particular period and place and in a specific context, of clothing, footwear, lifestyle, accessories, makeup, hairstyle, and body posture.",
  },
  {
    name: "Fabric + Care",
    content: `<ul class="list-disc list-inside leading-7">
    <li>Made from a sheer Belgian power micromesh.</li>
    <li>
    74% Polyamide (Nylon) 26% Elastane (Spandex)
    </li>
    <li>
    Adjustable hook & eye closure and straps
    </li>
    <li>
    Hand wash in cold water, dry flat
    </li>
  </ul>`,
  },

  {
    name: "How it Fits",
    content:
      "Use this as a guide. Preference is a huge factor — if you're near the top of a size range and/or prefer more coverage, you may want to size up.",
  },
  {
    name: "FAQ",
    content: `
    <ul class="list-disc list-inside leading-7">
    <li>All full-priced, unworn items, with tags attached and in their original packaging are eligible for return or exchange within 30 days of placing your order.</li>
    <li>
    Please note, packs must be returned in full. We do not accept partial returns of packs.
    </li>
    <li>
    Want to know our full returns policies? Here you go.
    </li>
    <li>
    Want more info about shipping, materials or care instructions? Here!
    </li>
  </ul>
    `,
  },
];

interface Props {
  panelClassName?: string;
  data?: typeof DEMO_DATA;
}

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "Description",
    children: (
      <div className="p-4 bg-white rounded-lg text-slate-600 text-sm dark:text-slate-300 leading-6">
        Fashion is a form of self-expression and autonomy at a particular period
        and place and in a specific context, of clothing, footwear, lifestyle,
        accessories, makeup, hairstyle, and body posture.
      </div>
    ),
    className:'mb-3 bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg'
  },
  {
    key: "2",
    label: "Fabric + Care",
    children: (
      <>
        <div className="p-4  bg-white rounded-lg text-slate-600 text-sm dark:text-slate-300 leading-6">
          <ul className="list-disc list-inside leading-7">
            <li>Made from a sheer Belgian power micromesh.</li>
            <li>74% Polyamide (Nylon) 26% Elastane (Spandex)</li>
            <li>Adjustable hook & eye closure and straps</li>
            <li>Hand wash in cold water, dry flat</li>
          </ul>
        </div>
      </>
    ),
    className:'mb-3 bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg'
  },
  {
    key: "3",
    label: "How it Fits",
    children: (
      <>
        <div className="p-4  text-slate-600 text-sm bg-white rounded-lg dark:text-slate-300 leading-6">
          Use this as a guide. Preference is a huge factor — if you're near the
          top of a size range and/or prefer more coverage, you may want to size
          up.
        </div>
      </>
    ),
    className:'mb-3 bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg'
  },

  {
    key: "4",
    label: "FAQ",
    children: (
      <>
        <div className="p-4 bg-white rounded-lg text-slate-600 text-sm dark:text-slate-300 leading-6">
          <ul className="list-disc list-inside leading-7">
            <li>
              All full-priced, unworn items, with tags attached and in their
              original packaging are eligible for return or exchange within 30
              days of placing your order.
            </li>
            <li>
              Please note, packs must be returned in full. We do not accept
              partial returns of packs.
            </li>
            <li>Want to know our full returns policies? Here you go.</li>
            <li>
              Want more info about shipping, materials or care instructions?
              Here!
            </li>
          </ul>
        </div>
      </>
    ),
    className:'mb-3 bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg',
    style:{borderRadius:'0.5rem'}
  },
];

const AccordionInfo: FC<Props> = ({
  panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6",
  data = DEMO_DATA,
}) => {
  return (
    <>
    <ConfigProvider
    theme={{
      components:{
        Collapse:{
          headerBg : '#ffffff',
          colorBorder:'transparent',
        }
      }
    }}
    >
      <Collapse
        defaultActiveKey={["1"]}
        // ghost
        bordered={false}
        items={items}
        expandIcon={({ isActive }) =>
          isActive ? (
            <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
          ) : (
            <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
          )
        }
        expandIconPosition="end"
      />
      </ConfigProvider>
    </>
  );
};

export default AccordionInfo;
