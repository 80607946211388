import React, { useState, FC, Dispatch, SetStateAction } from "react";
import { Modal,Button, ConfigProvider } from "antd";
import ComingSoon from 'images/Coming2.png';

interface ModalComingSoonProps {
  isModal: boolean;
  setIsModal: Dispatch<SetStateAction<boolean>>;
}

const ModalComingSoon: FC<ModalComingSoonProps> = (props) => {
  const { isModal, setIsModal } = props;

  const handleOk = () => {
    setIsModal(false);
  };
  const handleCancel = () => {
    setIsModal(false);
  };

  return (
    <>
      <Modal
        open={isModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer = {[
        <ConfigProvider
        theme={{
            components:{
                Button:{
                    defaultBorderColor:'#0f172a',
                    defaultHoverBg : '#0f172a',
                    defaultBg : '#0958d9',
                    defaultHoverBorderColor: '#0f172a',
                    defaultColor: '#ffffff',
                    defaultHoverColor:'#ffffff'
                }
            }
        }}
        >
        <Button key="back" type="default" onClick={handleCancel} size="large" className="bg-slate-900 px-20">
            Ok
          </Button>
          </ConfigProvider>,
        ]}
        width={1300}
      >
        <div className="text-center">
        <img src={ComingSoon} alt="Coming Soon"  className=""/>
        </div>
      </Modal>
    </>
  );
};

export default ModalComingSoon;
