import React,{useState} from "react";
import ModalComingSoon from "components/ModalComingSoon";

 const AvatarDropdown = () =>{
  const [isModalOpen,setIsModalOpen] = useState(false)
  return (
    <>
    <div className="AvatarDropdown ">
      <div className="relative">
          <>
            <button
              className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}
              onClick={()=>{setIsModalOpen(true)}}
            >
              <svg
                className=" w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
     
      </div>
    </div>
    <ModalComingSoon isModal={isModalOpen} setIsModal={setIsModalOpen} />
    </>
  );
}

export default AvatarDropdown;