import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { NavLink } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Categories",
    menus: [
      {
        href: "page-collection",
        label: "Women",
        data: { heading_name: "Women" },
      },
      {
        href: "page-collection",
        label: "Kids",
        data: { heading_name: "Kids" },
      },
      {
        href: "page-collection",
        label: "Beauty",
        data: { heading_name: "Beauty" },
      },
    ],
  },
  {
    id: "1",
    title: "Pages",
    menus: [
      { href: "/about", label: "About Us", data: "" },
      { href: "/contact", label: "Contact Us", data: "" },
    ],
  },
  {
    id: "2",
    title: "Help",
    menus: [
      { href: "#", label: "Terms & Conditions", data: "" },
      { href: "#", label: "Privacy Policy", data: "" },
    ],
  },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "#", label: "Discussion Forums" },
  //     { href: "#", label: "Code of Conduct" },
  //     { href: "#", label: "Contributing" },
  //     { href: "#", label: "API Reference" },
  //   ],
  // },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {/* <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                rel="noopener noreferrer"
              >
                {item.label}
              </a> */}
              <NavLink
                to={item.href}
                key={index}
                className={
                  "text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                }
                state={{ customData: item.data }}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer bg-[#f0f8ff] relative py-16 lg:pt-24 lg:pb-20 border-t border-neutral-200 dark:border-neutral-700 dark:bg-slate-800">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-4">
            <Logo />
          </div>
          {/* <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div> */}
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}

        <div className="text-sm">
          <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
            Social
          </h2>
          <div className="flex items-center">
            <SocialsList1 className="flex items-center mt-5 space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>
      </div>
      <div className="container flex justify-center mt-10">
        <div className="font-normal text-neutral-700 dark:text-neutral-200 uppercase">
          {" "}
          <span className="font-semibold">
            &#169; {new Date().getFullYear()}
          </span>{" "}
          shri limbas the new era of fashion -
          <span className="font-semibold"> all rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
